import { Scene, Mesh, MeshBuilder } from "@babylonjs/core";
import { SkyMaterial } from "@babylonjs/materials/sky/skyMaterial";

export class SkyBox {
  public skybox: Mesh;
  public skyboxMaterial: SkyMaterial;

  constructor(private scene: Scene) {
    this.skyboxMaterial = new SkyMaterial("skyMaterial", scene);
    this.skybox = MeshBuilder.CreateBox("SkyBox", { size: 1000, updatable: false, sideOrientation: Mesh.BACKSIDE }, scene);
  }

  public create(): Mesh {
    this.skyboxMaterial.backFaceCulling = false;
    this.skybox.material = this.skyboxMaterial;
    this.skyboxMaterial.inclination = -0.35;
    this.skybox.material = this.skyboxMaterial;
    console.log("Skybox created");
    return this.skybox;
  }

  public dispose() {
    this.skyboxMaterial.dispose();
    this.skybox.dispose();
  }
}
