import { Vector3, Mesh, MeshBuilder, StandardMaterial, Scene, Texture } from "@babylonjs/core";

export class ImageWall {
  private material: StandardMaterial;
  private texture: Texture;
  public mesh: Mesh;

  constructor(private scene: Scene, private number: number) {
    this.material = new StandardMaterial("myMaterial", this.scene);
    this.texture = new Texture("cube_wallpaper/photo" + number + ".webp", this.scene);
    this.mesh = MeshBuilder.CreateGround("imageWall", { width: 313, height: 172, subdivisions: 200 }, this.scene);
    // You can set up the material and texture here or in the create method
    this.create();
  }

  public create(): Mesh {
    // Assuming texture and material are set up, either in the constructor or elsewhere
    this.texture.vAng = Math.PI;
    this.texture.uAng = Math.PI;
    this.material.diffuseTexture = this.texture;
    this.mesh.checkCollisions = false;
    this.mesh.isPickable = false;
    this.mesh.position = new Vector3(0, 0, -200);
    this.mesh.rotation = new Vector3(Math.PI / 2, 0, 0);
    this.mesh.scaling = new Vector3(2, 2, 2);
    this.mesh.receiveShadows = true;
    this.mesh.material = this.material;
    //this.mesh.layerMask = 0x10000000;
    return this.mesh;
  }

  public updateTexture(dataUrl: string) {
    if (this.material.diffuseTexture) {
      this.material.diffuseTexture.dispose(); // Alte Textur freigeben, falls vorhanden
    }

    const newTexture = new Texture(dataUrl, this.scene);
    newTexture.vScale = -1; // Vertikale umkehren
    newTexture.uScale = -1; // Vertikale umkehren
    this.material.diffuseTexture = newTexture;
  }

  public dispose() {
    this.mesh.dispose();
    this.material.dispose();
    this.texture.dispose();
  }

  // ... other instance methods ...
}
