<template>
  <div id="canvasZone">
    <canvas id="ViewPort"></canvas>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { BasicScene } from "@/Cube/BasicScene";

export default defineComponent({
  name: "CubeComponent",
  components: {},
  setup() {
    const basicScene = ref<BasicScene | null>(null);
    onMounted(() => {
      const canvasViewPort = document.querySelector("#ViewPort") as HTMLCanvasElement;
      basicScene.value = new BasicScene(canvasViewPort);
    });
  },
});
</script>

