import { Vector3, Mesh, MeshBuilder, StandardMaterial, Color3, Texture, Scene } from "@babylonjs/core";

import { FurMaterial } from "@babylonjs/materials/fur/furMaterial";

export class Ground {
  public ground: Mesh;
  public material: StandardMaterial;

  constructor(private scene: Scene) {
    this.material = new StandardMaterial("myMaterial", this.scene);
    this.ground = MeshBuilder.CreateGround("ground", { width: 100, height: 100, subdivisions: 200 }, scene);
  }
  public create(scene: Scene): Mesh {
    this.material.diffuseColor = new Color3(0.0, 0.5, 0.2);
    this.material.specularColor = new Color3(0.2, 0.2, 0.2);
    this.ground.checkCollisions = false;
    this.ground.isPickable = false;
    this.ground.position = new Vector3(0, -5, 0);
    //ground.rotation = new Vector3(Math.PI/2,0,0);
    this.ground.receiveShadows = true;

    this.ground.material = this.GetGrass(scene);
    // const grassMesh = FurMaterial.FurifyMesh(this.ground, 20);
    return this.ground;
  }

  private GetGrass(scene: Scene): FurMaterial {
    const grassMaterial = new FurMaterial("grass", scene);
    grassMaterial.furLength = 0.0;
    grassMaterial.furAngle = 0.5;
    grassMaterial.furColor = new Color3(1, 1, 1);
    grassMaterial.diffuseTexture = new Texture("grassnoise3.jpg", scene);
    grassMaterial.furTexture = FurMaterial.GenerateTexture("furTexture", scene);
    grassMaterial.furSpacing = 0.1;
    grassMaterial.furDensity = 30;
    grassMaterial.furSpeed = 2000;
    grassMaterial.furOcclusion = 0.2;
    grassMaterial.furGravity = new Vector3(0, -1, 0);

    return grassMaterial;
  }
  public dispose() {
    this.ground.dispose();
    this.material.dispose();
  }
}
