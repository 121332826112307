import { Scene, DirectionalLight, Vector3 } from "@babylonjs/core";

export class SunLight {
  public sunlight: DirectionalLight;

  constructor(private scene: Scene) {
    this.sunlight = new DirectionalLight("sunLight", new Vector3(Math.PI / 2, -Math.PI / 2, 0.5), scene);
  }

  public create(): DirectionalLight {
    // sunLight.position = new Vector3(0, 100, 0); // Position der Lichtquelle
    this.sunlight.intensity = 1; // Lichtintensitätl
    this.sunlight.shadowEnabled = true; // Schatten aktivieren
    return this.sunlight;
  }

  // In der SunLight-Klasse
  public updateLightDirection(alpha: number): void {
    const x = Math.cos(alpha) * 100;
    const y = Math.sin(alpha) * 100;
    const z = 0; // Je nach Bedarf anpassen
    this.sunlight.direction = new Vector3(x, y, z);
  }

  public dispose() {
    this.sunlight.dispose();
  }
}
