import { Scene, Mesh, MeshBuilder, Vector3, StandardMaterial } from "@babylonjs/core";

import { AdvancedDynamicTexture, Rectangle, Control, Image } from "@babylonjs/gui";

export class ProgressBar {
  private guiTexture: AdvancedDynamicTexture;
  private progressBar: Rectangle;
  private progressIndicator: Rectangle;
  public rotatingLogo: Mesh;
  constructor(private scene: Scene) {
    // GUI-Textur erstellen
    this.scene = scene;

    this.guiTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI", true, scene);
    this.guiTexture.background = "#00000000";
    // Progressbar-Hintergrund
    this.progressBar = new Rectangle("progressBar");
    this.progressBar.width = 0.3; // 20% der Breite des Bildschirms
    this.progressBar.height = "20px";
    this.progressBar.cornerRadius = 20;
    this.progressBar.color = "white";
    this.progressBar.thickness = 2;
    this.progressBar.background = "grey";
    this.progressBar.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.progressBar.top = "70%";
    this.guiTexture.addControl(this.progressBar);

    // Fortschrittsbalken
    this.progressIndicator = new Rectangle("progressIndicator");
    this.progressIndicator.height = "100%";
    this.progressIndicator.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.progressIndicator.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.progressIndicator.background = "#00A658";
    this.progressBar.addControl(this.progressIndicator);
    this.rotatingLogo = new Mesh("cube", scene);
  }

  public update(progress: number) {
    // Fortschritt als Prozentsatz der Breite des Hintergrunds
    this.progressIndicator.width = `${progress * 100}%`;
  }

  public remove() {
    // Entfernen der Progressbar
    this.guiTexture.removeControl(this.progressBar);
    this.RemoveRotatingLogo();
  }

  public async addRotatingLogo() {
    // Erstellen des Würfel-Meshs
    this.rotatingLogo = MeshBuilder.CreateBox("logoCube", { size: 10 }, this.scene);
    this.rotatingLogo.position = new Vector3(-0, 0, 80); // Position anpassen
    this.rotatingLogo.scaling = new Vector3(1, 1, 1); // Skalierung anpassen
    this.rotatingLogo.rotation = new Vector3(Math.random(), Math.random(), Math.random()); // Rotation anpassen

    // Erstellen der Textur
    const texture = new AdvancedDynamicTexture("logoTexture", 1024, 1024, this.scene);
    const logo = new Image("", "logo.png");
    console.log("logo geladen");
    texture.addControl(logo);

    // Erstellen des Materials
    const material = new StandardMaterial("logoMaterial", this.scene);
    material.diffuseTexture = texture;

    // Zuweisen des Materials zum Würfel
    this.rotatingLogo.material = material;

    await this.rotateLogoAsync(); // Das Logo asynchron rotieren

    this.scene.addMesh(this.rotatingLogo);
  }

  public RemoveRotatingLogo() {
    // Erstellen des Würfel-Meshs

    console.log("logo zerstört");
    this.rotatingLogo?.dispose();
  }

  private async rotateLogoAsync() {
    return new Promise<void>(() => {
      const rotationSpeed = 0.01; // Die Rotationsgeschwindigkeit einstellen

      const rotateFunction = () => {
        this.rotatingLogo.rotation.y += rotationSpeed;
        this.rotatingLogo.rotation.x += rotationSpeed;
      };

      this.scene.onBeforeRenderObservable.add(rotateFunction);
    });
  }
}
