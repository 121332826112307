import { UniversalCamera, Scene, Vector3 } from "@babylonjs/core";
export class StaticCamera {
  public camera: UniversalCamera;

  constructor(private scene: Scene) {
    this.camera = new UniversalCamera("camera", new Vector3(0, 0, 180), scene);
  }

  public create(): UniversalCamera {
    this.camera.setTarget(Vector3.Zero());

    return this.camera;
  }
  public dispose() {
    this.camera.dispose();
  }
}
