import { Scene, Engine, HemisphericLight, Mesh, UniversalCamera, Vector3, DefaultRenderingPipeline } from "@babylonjs/core";

import { CustomGizmoManager } from "./CustomGizmoManager"; // Importieren des CustomGizmoManagers

import "@babylonjs/loaders";
import { SunLight } from "./SunLight";
import { Ground } from "./Ground";
import { HemiLight } from "./HemiLight";
import { RotatingCamera } from "./ArcRotateCamera";
import { StaticCamera } from "./StaticCamera";
import { Cube } from "./Cube";
import { MouseController } from "./MouseController";
import { SkyBox } from "./SkyBox";
import { SkyMaterial } from "@babylonjs/materials";
import { ImageWall } from "./ImageWall";
import { GUI } from "./GUI";
import { ProgressBar } from "./ProgressBar";

export class BasicScene {
  private isDragging: boolean = false;
  private canvas: HTMLCanvasElement;
  private mainScene: Scene;
  private loadingScene: Scene;
  private engine: Engine;
  private rotatingCamera: RotatingCamera;
  private staticCamera: StaticCamera;
  private sunlight: SunLight;
  private mouseController?: MouseController;

  private skyBox: SkyBox;

  public cube: Cube;
  private hemilight: HemiLight;

  private gui?: GUI;

  private ground?: Ground;
  private modeChanged: boolean; // Neue Eigenschaft, um zu verfolgen, ob ein Update erforderlich ist

  private skybox?: Mesh;
  public isInFullScreen: boolean;
  private manualUpdate: boolean;
  private alpha: number;
  public mode!: number;
  private imageWall: ImageWall;

  private cubeIcons: Mesh[];
  public progressBar: ProgressBar;
  public actualCubeType: number;

  constructor(canvas: HTMLCanvasElement) {
    this.actualCubeType = 0;
    this.modeChanged = false;
    this.alpha = 6;
    this.mode = 1;
    this.manualUpdate = false;
    this.canvas = canvas;

    this.cubeIcons = [];

    const canvasWidth = 1920; // Startbreite
    const canvasHeight = 1080; // Starthöhe
    this.canvas.width = canvasWidth;
    this.canvas.height = canvasHeight;

    this.engine = new Engine(this.canvas, false, { preserveDrawingBuffer: true, stencil: true, disableWebGL2Support: false });
    this.mainScene = new Scene(this.engine);
    CustomGizmoManager.initialize(this.mainScene); // Initialisieren des CustomGizmoManagers
    this.loadingScene = new Scene(this.engine);
    const loadingCamera = new UniversalCamera("camera", new Vector3(0, 0, 180), this.loadingScene);
    loadingCamera.setTarget(Vector3.Zero());
    this.loadingScene.activeCamera = loadingCamera;
    this.hemilight = new HemiLight(this.loadingScene);
    this.hemilight.create();
    this.hemilight.hemiLight.intensity = 1.5;

    this.progressBar = new ProgressBar(this.loadingScene);
    this.progressBar.addRotatingLogo();
    this.imageWall = new ImageWall(this.mainScene, this.mode);
    this.staticCamera = new StaticCamera(this.mainScene);
    this.rotatingCamera = new RotatingCamera(this.mainScene);
    this.sunlight = new SunLight(this.mainScene);
    this.skyBox = new SkyBox(this.mainScene);
    this.ground = new Ground(this.mainScene);
    this.hemilight = new HemiLight(this.mainScene);
    this.isInFullScreen = false;
    this.cube = new Cube(this.mainScene, this.progressBar, this.sunlight);

    this.mouseController = new MouseController(this.mainScene, this.cube.cubes);

    window.addEventListener("resize", () => this.handleWindowResize());
    window.addEventListener("orientationchange", () => this.handleWindowResize());
    this.engine.runRenderLoop(() => {
      if (this.cube.isLoadingComplete) {
        this.mainScene.render();
      } else {
        this.loadingScene.render();
      }
    });
    this.createMainScene();
  }

  public async createMainScene() {
    this.disposeAll();

    //this.mouseController.create( this.scene);
    this.skyBox = new SkyBox(this.mainScene);
    this.skybox = this.skyBox.create();
    (this.skybox.material as SkyMaterial).inclination = Math.cos(this.alpha);
    this.mainScene.activeCamera = this.staticCamera.create();
    await this.cube.loadAllCubeMeshes();

    //const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || 'msMaxTouchPoints' in navigator || window.matchMedia("(pointer: coarse)").matches;
    //if (isTouchDevice) {
    //  this.touchControllerForObject = new TouchControllerForObject(this.scene, cube);
    //  this.touchControllerForObject.create( this.scene);
    //}
    //else {
    //
    //}
    const Hemi2 = new HemisphericLight("hemiLight", new Vector3(0, 1, 1));
    Hemi2.intensity = 0.5;
    this.hemilight = new HemiLight(this.mainScene);
    this.hemilight.create();
    this.hemilight.hemiLight.intensity = 1.5;

    this.sunlight = new SunLight(this.mainScene);
    this.sunlight.create();
    this.sunlight.sunlight.position = new Vector3(Math.cos(this.alpha) * 100, Math.sin(this.alpha) * 100, 0);
    this.sunlight.sunlight.intensity = 0.0;
    this.imageWall = new ImageWall(this.mainScene, this.mode);
    this.imageWall.create();
    this.gui = new GUI(this.mainScene, this.staticCamera.camera, this.canvas, this.imageWall, this, this.engine);
    this.mouseController = new MouseController(this.mainScene, this.cube.cubes);

    this.mainScene.createDefaultEnvironment();
    this.handleWindowResize();

    const defaultPipeline = new DefaultRenderingPipeline(
      "default", // Der Name der Pipeline
      false, // HDR aktiviert (true) oder deaktiviert (false)
      this.mainScene, // Die Szene, zu der die Pipeline gehört
      [this.staticCamera.camera] // Kameras, die die Pipeline verwenden sollen
    );

    // Konfigurieren Sie das Anti-Aliasing
    defaultPipeline.fxaaEnabled = true;
  }

  private disposeAll(): void {
    console.log("disposeAll");
    //  if (this.touchControllerForObject) {this.touchControllerForObject.dispose();}
    //  if (this.mouseController) {this.mouseController.dispose();}

    if (this.rotatingCamera) this.rotatingCamera.dispose();
    if (this.staticCamera) this.staticCamera.dispose();
    if (this.cube) this.cube.dispose();
    if (this.skyBox) this.skyBox.dispose();
    if (this.ground) this.ground.dispose();
    if (this.imageWall) this.imageWall.dispose();
    if (this.mainScene.activeCamera) this.mainScene.activeCamera.dispose();
    if (this.imageWall) this.imageWall.dispose();
    if (this.sunlight) this.sunlight.dispose();
    if (this.hemilight) this.hemilight.dispose();
  }

  public updateSunPosition(hour: number): void {
    this.manualUpdate = true;
    this.alpha = ((hour % 24) / 24) * Math.PI * 1;
    this.sunlight.updateLightDirection(this.alpha);
    this.mainScene.render();
    console.log("updateSunPosition");
  }

  public recreateGUI() {
    if (this.gui) {
      this.gui.destroy();
      this.gui = new GUI(this.mainScene, this.staticCamera.camera, this.canvas, this.imageWall, this, this.engine);
      this.gui.guiTexture.scaleTo(1920, 1080);
    }
  }

  public handleWindowResize() {
    this.recreateGUI;
    let windowed = true;
    document.fullscreenElement != null ? (windowed = false) : (windowed = true);
    console.log("resized");
    // Ziel-Seitenverhältnis
    const targetAspectRatio = 16 / 9;
    // Aktuelle Größe des Browserfensters
    const viewportWidth = document.documentElement.clientWidth;
    const viewportHeight = document.documentElement.clientHeight;
    console.log("viewportWidth: " + viewportWidth);
    console.log("viewportHeight: " + viewportHeight);
    // Bestimmen des Skalierungsfaktors
    let scale;
    if (viewportWidth / viewportHeight > targetAspectRatio) {
      // Fenster ist relativ schmaler
      scale = viewportHeight / 9;
    } else {
      // Fenster ist relativ breiter
      scale = viewportWidth / 16;
    }
    const newCanvasWidth = 16 * scale;
    const newCanvasHeight = 9 * scale;

    if (windowed) {
      if (this.isFirefox()) {
        this.engine.setHardwareScalingLevel(1);
        this.recreateGUI();
        this.canvas.width = newCanvasWidth / 1.2;
        this.canvas.height = newCanvasHeight / 1.2;

        this.canvas.style.width = newCanvasWidth / 1.2 + "px";
        this.canvas.style.height = newCanvasHeight / 1.2 + "px";
      } else {
        this.canvas.width = newCanvasWidth;
        this.canvas.height = newCanvasHeight;

        this.canvas.style.width = newCanvasWidth + "px";
        this.canvas.style.height = newCanvasHeight + "px";
      }
    } else {
      if (this.isFirefox()) {
        if (viewportWidth > 1920) {
          //this.engine.setSize(viewportWidth, viewportHeight);
          this.engine.setHardwareScalingLevel(1.3);

          /*
          this.canvas.width = viewportWidth;
          this.canvas.height = viewportHeight;

          this.canvas.style.width = viewportWidth + "px";
          this.canvas.style.height = viewportHeight + "px";
          */
          //alert(
          //"Augrund eines Fehlers im Firefox ist die Darstellung in 4K nicht möglich. Bitte verwenden Sie einen anderen Browser. Wir arbeiten an einer Lösung. Drücken Sie ESC um aus dem Vollbildmodus zu gelangen."
          //);

          //if (this.gui) this.gui.guiTexture.scaleTo(viewportWidth / 1.3, viewportHeight / 1.3);
        } else {
          this.engine.setHardwareScalingLevel(1);
          this.canvas.width = viewportWidth;
          this.canvas.height = viewportHeight;
          this.canvas.style.width = viewportWidth + "px";
          this.canvas.style.height = viewportHeight + "px";
        }
      } else {
        this.canvas.width = viewportWidth;
        this.canvas.height = viewportHeight;
        this.canvas.style.width = viewportWidth + "px";
        this.canvas.style.height = viewportHeight + "px";
      }
    }
  }

  public isFirefox(): boolean {
    return navigator.userAgent.indexOf("Firefox") !== -1;
  }
}
