import { Vector3, HemisphericLight, Scene } from "@babylonjs/core";

export class HemiLight {
  public hemiLight: HemisphericLight;

  constructor(private scene: Scene) {
    this.hemiLight = new HemisphericLight("hemiLight", new Vector3(5, -0.2, 1.5), scene);
  }
  public create(): HemisphericLight {
    this.hemiLight.intensity = 0.2;
    //this.hemiLight.excludeWithLayerMask = 0x10000000;
    return this.hemiLight;
  }

  public dispose() {
    this.hemiLight.dispose();
  }
}
