import { ArcRotateCamera, Scene, Vector3, Mesh } from "@babylonjs/core";
export class RotatingCamera {
  private camera: ArcRotateCamera;

  constructor(private scene: Scene) {
    this.camera = new ArcRotateCamera("camera", 0, 0, 30, Vector3.Zero(), scene);
  }

  public create(targetMesh: Mesh, canvas: HTMLCanvasElement): ArcRotateCamera {
    this.camera.lowerRadiusLimit = 2.5;
    this.camera.upperRadiusLimit = 200;
    this.camera.setTarget(targetMesh.position || Vector3.Zero());
    this.camera.attachControl(canvas, true);
    this.camera.wheelPrecision = 10; // Setzen Sie die Präzision für das Zoomen mit dem Mausrad

    // Setzen Sie die Trägheit für das Schwenken (links und rechts)
    this.camera.panningInertia = 0;
    return this.camera;
  }
  public dispose() {
    this.camera.dispose();
  }
}
